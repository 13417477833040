import { usePracticeAppSelector } from '../../../../redux';
import { useOrderAction } from '../../../../redux/orders/orders.actions';
import { useOpenOrderDetailPage } from '../../../labs/LabsUtils';
import { OrderReturnActionsCell } from '../../../labs/components/ReturnSummaryRows.graphql';
import { useAskDoctorResponseDialog } from '../../../labs/order-detail-v2/components/AskDoctorResponse';
import { PracticeSidebarStatusCount_Query } from '../../../labs/orders-v2/useOrderStatusCounts.graphql';
import { ReviewThisOrderEntrypoint } from './Reviews/ReviewThisOrder';
import { ShowGivenOrderFeedback } from './Reviews/ShowGivenOrderFeedback.graphql';
import { useApolloClient } from '@apollo/client';
import { OrderSummaryRow, OrderSummaryRowButtons } from '@orthly/dentin';
import type { FragmentType, PracticeOrderSummaryRowSalesOrder_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabOrderStatus, LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { OrderDetailTrackerV2, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface PracticeOrderSummaryRowProps {
    fromInbox?: boolean;
    isLast?: boolean;
    onOpen?: () => void;
    orderFragment: FragmentType<typeof PracticeOrderSummaryRowSalesOrder_FragmentFragmentDoc>;
    refetch: () => Promise<void>;
}

const PracticeStepperSectionV2SalesOrder_Fragment = graphql(`
    fragment PracticeStepperSectionV2SalesOrder_Fragment on OrderPracticeListViewEntry {
        id
        can_edit
        can_refabricate
        can_submit_feedback
        can_submit_tryin_feedback
        reviewed_items_descriptions
        delivery_date
        is_delivered
        is_on_hold

        active_return {
            status
        }

        order_tracker_entries(timezone_offset_minutes: $timezone_offset_minutes) {
            active
            alert
            infoTooltip
            style
            subtitle
            subtitleAttachment
            title
            infoTooltip
            style
        }

        ...PracticeShowGivenOrderFeedbackSalesOrder_Fragment
        ...PracticeOrderReturnActionsCellSalesOrder_Fragment
    }
`);

const PracticeStepperSectionV2: React.VFC<{
    orderFragment: FragmentType<typeof PracticeStepperSectionV2SalesOrder_Fragment>;
    refetch: () => Promise<void>;
}> = ({ orderFragment, refetch }) => {
    const session = useSession();
    const { value: enableDesignPreviewETA } = useFeatureFlag('enableDesignPreviewETA');

    const order = getFragmentData(PracticeStepperSectionV2SalesOrder_Fragment, orderFragment);

    if (order.active_return) {
        return <OrderReturnActionsCell orderFragment={order} refetch={refetch} />;
    }

    if (order.is_delivered) {
        if (order.reviewed_items_descriptions.length) {
            return <ShowGivenOrderFeedback orderFragment={order} />;
        }
        return (
            <ReviewThisOrderEntrypoint
                orderId={order.id}
                canRefabricate={order.can_refabricate}
                canSubmitFeedback={order.can_submit_feedback}
                canSubmitTryInFeedback={order.can_submit_tryin_feedback}
                delivery_date={order.delivery_date}
            />
        );
    }

    return (
        <OrderDetailTrackerV2
            orderTrackerEntries={order.order_tracker_entries}
            isOnHold={order.is_on_hold}
            isPractice={session?.organization_type === 'practice'}
            isLab={session?.organization_type === 'lab'}
            showDesignPreviewETA={enableDesignPreviewETA}
        />
    );
};

const orderStatusToPracticeOrderStatus: Record<LabsGqlLabOrderStatus, LabsGqlPracticeOrderStatus> = {
    [LabsGqlLabOrderStatus.Cancelled]: LabsGqlPracticeOrderStatus.Cancelled,
    [LabsGqlLabOrderStatus.OnHold]: LabsGqlPracticeOrderStatus.OnHold,
    [LabsGqlLabOrderStatus.Fabrication]: LabsGqlPracticeOrderStatus.Fabrication,
    [LabsGqlLabOrderStatus.Delivered]: LabsGqlPracticeOrderStatus.Delivered,
    [LabsGqlLabOrderStatus.NeedsRefabrication]: LabsGqlPracticeOrderStatus.All,
    [LabsGqlLabOrderStatus.NeedsReview]: LabsGqlPracticeOrderStatus.New,
    [LabsGqlLabOrderStatus.Shipped]: LabsGqlPracticeOrderStatus.Shipped,
    [LabsGqlLabOrderStatus.New]: LabsGqlPracticeOrderStatus.New,
};

function useOnOrderSummaryRowEdited(isInbox: boolean, refetch: () => Promise<void>) {
    const client = useApolloClient();

    const setListPageStatus = useOrderAction('CHANGE_STATUS');
    const currentOrderListStatus = usePracticeAppSelector(s => s.orders.status);
    // if we're on the order list page and the edited order is now in a different status than the page is on, we want
    // to change the screen so it shows up
    const shouldUpdateOrderListStatus = !isInbox && currentOrderListStatus !== LabsGqlPracticeOrderStatus.All;
    return React.useCallback(
        (newOrderStatus?: LabsGqlLabOrderStatus) => {
            const newScreenStatus = newOrderStatus
                ? orderStatusToPracticeOrderStatus[newOrderStatus]
                : currentOrderListStatus;
            // if we're not in the inbox, and the new order status is different from the current screen, we want to change the screen
            if (shouldUpdateOrderListStatus && newScreenStatus !== currentOrderListStatus) {
                setListPageStatus(newScreenStatus);
                // we can exit here because changing the screen will refetch the list view and status counts
                return;
            }
            // If we're in the inbox, we refetch the InboxActiveOrders query
            // For the list view, we want to refetch the sidebar counts and the IDs in the list view (to trigger a refetch of order details)
            if (!isInbox) {
                void client.refetchQueries({ include: [PracticeSidebarStatusCount_Query] });
            }
            void refetch();
        },
        [client, currentOrderListStatus, isInbox, setListPageStatus, shouldUpdateOrderListStatus, refetch],
    );
}

const PracticeOrderSummaryRowSalesOrder_Fragment = graphql(`
    fragment PracticeOrderSummaryRowSalesOrder_Fragment on OrderPracticeListViewEntry {
        id
        updated_at
        doctor_name
        item_product_unit_types
        items_description
        order_number
        patient_name
        is_on_hold
        does_need_review
        replaced_by_order_id
        can_edit
        can_refabricate
        can_submit_feedback
        waxup_status
        active_doctor_request_id
        is_delivered
        is_on_hold
        is_refabricated
        is_canceled

        active_return {
            status
        }

        ...PracticeStepperSectionV2SalesOrder_Fragment
    }
`);

export const PracticeOrderSummaryRow: React.VFC<PracticeOrderSummaryRowProps> = ({
    orderFragment,
    fromInbox,
    isLast,
    onOpen,
    refetch,
}) => {
    const isInbox = fromInbox ?? true;
    const openOrder = useOpenOrderDetailPage();
    const order = getFragmentData(PracticeOrderSummaryRowSalesOrder_Fragment, orderFragment);

    const onDoctorResponseSuccess = useOnOrderSummaryRowEdited(isInbox, refetch);
    const doctorRequestId = order.active_doctor_request_id ?? null;
    const [setAskDoctorResponseOpen, askDoctorResponseDialog] = useAskDoctorResponseDialog({
        doctorRequestId,
        orderId: order.id,
        onSuccess: onDoctorResponseSuccess,
    });

    const handleClick = React.useCallback(() => {
        return onOpen ? onOpen() : openOrder(order.id);
    }, [onOpen, openOrder, order.id]);

    return (
        <>
            <OrderSummaryRow
                onClick={handleClick}
                openOrder={openOrder}
                stepperSection={<PracticeStepperSectionV2 orderFragment={order} refetch={refetch} />}
                isLast={isLast}
                isInbox={isInbox}
                Buttons={
                    <OrderSummaryRowButtons
                        setAskDoctorResponseOpen={setAskDoctorResponseOpen}
                        shouldShowResolveButton={!!order.active_doctor_request_id}
                        orderId={order.id}
                        is_delivered={order.is_delivered}
                        waxup_status={order.waxup_status ?? null}
                        can_refabricate={order.can_refabricate}
                        can_submit_feedback={order.can_submit_feedback}
                        can_edit={order.can_edit}
                    />
                }
                orderId={order.id}
                orderNumber={order.order_number}
                needsAttention={!!order.active_return || order.is_on_hold || order.does_need_review}
                isCanceled={order.is_canceled}
                patientName={order.patient_name}
                itemsDescription={order.items_description}
                productUnitTypes={order.item_product_unit_types}
                doctorName={order.doctor_name}
            />
            {askDoctorResponseDialog}
        </>
    );
};
