import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { ProfileSectionAlert } from '../../components/ProfileSectionAlert';
import { SettingsSection } from '../../components/SettingsSection';
import { DeliveryAddresses } from '../addresses/DeliveryAddresses';
import { EditDeliveryAddressModal } from '../addresses/EditDeliveryAddressModal';
import { PaymentMethodsTitleAction } from '../payment/PaymentMethodsTitleAction';
import { usePaymentMethodControls } from '../payment/usePaymentMethodControls';
import { ChangeDisplayName } from './ChangeDisplayName';
import { ChangePhoneNumber } from './ChangePhoneNumber';
import { DownloadTermsOfUse } from './DownloadTermsOfUse';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlPracticeOnboardingStep } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { PaymentMethods } from '@orthly/veneer';
import React from 'react';

const TransitionOnboardingStep_Mutation = graphql(`
    mutation TransitionOnboardingStep_Mutation($args: PracticeOnboardingTransitionStepInput!) {
        transitionStep(args: $args) {
            id
            completed_steps
        }
    }
`);

export const PracticeSecuritySettings: React.FC = () => {
    const partnerId = useSession()?.organization_id;
    const [open, setOpen] = React.useState(false);
    const { methods: loadedMethods, loading, removeMethod, setDefaultMethod } = usePaymentMethodControls();

    const [transitionOnboardingStep] = useMutation(TransitionOnboardingStep_Mutation);
    const onOnboardingStepSuccess = async (completedStep: LabsGqlPracticeOnboardingStep) => {
        await transitionOnboardingStep({
            variables: {
                args: { completedSteps: [completedStep] },
            },
        });
    };

    return (
        <PracticeSettingsBase
            title={'Practice settings'}
            Content={
                <>
                    <SettingsSection
                        title={'Practice name'}
                        subtitle={'The name of your practice as displayed within Dandy'}
                    >
                        <ChangeDisplayName />
                    </SettingsSection>

                    <SettingsSection
                        title={'Practice phone number'}
                        subtitle={"Your practice's front desk phone number"}
                    >
                        <ChangePhoneNumber />
                    </SettingsSection>

                    <SettingsSection
                        title={'Delivery address'}
                        subtitle={'These addresses are available during checkout'}
                        longSection
                        action={
                            <EditDeliveryAddressModal
                                open={open}
                                setOpen={setOpen}
                                onSuccess={() => onOnboardingStepSuccess(LabsGqlPracticeOnboardingStep.DeliveryInfo)}
                                partnerId={partnerId ?? ''}
                                buttonVariant={'ghost'}
                                buttonStyles={{ padding: 0, height: 'unset', marginTop: 4 }}
                            />
                        }
                    >
                        <DeliveryAddresses partnerId={partnerId ?? ''} />
                    </SettingsSection>

                    <SettingsSection
                        title={'Payment methods'}
                        subtitle={'Used to pay your invoices'}
                        longSection
                        action={
                            <PaymentMethodsTitleAction
                                onSuccess={() => onOnboardingStepSuccess(LabsGqlPracticeOnboardingStep.BillingInfo)}
                            />
                        }
                    >
                        {!loading && loadedMethods?.length === 0 && (
                            <ProfileSectionAlert
                                message={"You haven't added a payment method yet!"}
                                severity={'error'}
                            />
                        )}
                        <PaymentMethods
                            partnerId={partnerId ?? ''}
                            loadedMethods={loadedMethods}
                            removeMethod={removeMethod}
                            setDefaultMethod={setDefaultMethod}
                        />
                    </SettingsSection>

                    <SettingsSection
                        title={'Practice documents'}
                        subtitle={'Documents and agreements between your practice and Dandy'}
                        longSection
                    >
                        <DownloadTermsOfUse />
                    </SettingsSection>
                </>
            }
        />
    );
};
