import { AnalyticsClient } from '../../analytics/analyticsClient';
import logo from '../../assets/icons/dandy_logo_green.svg';
import person from '../../assets/icons/person_green.svg';
import scanner from '../../assets/icons/scanner_green.svg';
import { GettingStartedLink } from './components/GettingStartedLink/GettingStartedLink';
import { TrainingDataIntakeCompletedCards } from './components/TrainingDataIntake/TrainingDataIntakeCompletedCards';
import { EquipmentTracker } from './components/equipment-tracker/EquipmentTracker';
import { useQuery, useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useGetPracticeTrainingDateQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useRootActionCommand } from '@orthly/ui';
import { FlossPalette, stylesFactory, useScreenIsMobile, Text, CloseIcon, Button, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    root: {
        background: FlossPalette.TAN,
        height: '100vh',
    },
    outerWrapper: {
        maxWidth: '1418px',
        margin: '0 auto 0 auto',
        padding: '40px 56px 48px 56px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 24px 48px 24px',
        },
    },
    divider: {
        margin: '40px 0 40px 0',
        display: 'block',
        height: 1,
        border: 0,
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    columns: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        gap: '24px',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
        },
    },
    buttonsLabel: {
        marginBottom: '24px',
    },
    buttons: {
        width: '816px',
        display: 'flex',
        gap: '24px',
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
    tracker: {
        flexGrow: 1,
    },
    modulesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 28,
        minWidth: '336px',
        maxWidth: '696px',
    },
}));

const BannerContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 24px',
    gap: '8px',
    height: '40px',
    background: FlossPalette.SECONDARY_FOREGROUND,
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: 0,
    cursor: 'pointer',
    [theme.breakpoints.between('sm', 'md')]: {
        marginTop: '56px',
    },
}));

const ProgressBarContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    width: '48px',
    height: '8px',
    background: FlossPalette.PRIMARY_BACKGROUND,
    border: `1px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
    borderRadius: '8px',
    flex: 'none',
    flexGrow: 0,
});

const InnerProgressBar = styled('div')({
    width: '24px',
    height: '8px',
    background: FlossPalette.PRIMARY_FOREGROUND,
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: 0,
});

const FinishSettingUpText = styled(Text)({
    fontSize: '14px !important',
    display: 'flex',
    flexDirection: 'row',
    flex: 'none',
    flexGrow: 1,
    alignItems: 'center',
    letterSpacing: '-0.01em',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
});

const ContinueText = styled(Text)({
    display: 'flex',
    flexDirection: 'row',
    flex: 'none',
    flexGrow: 0,
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    fontWeight: '600',
    '&&': {
        fontSize: '14px',
        lineHeight: '24px',
    },
});

const CloseButton = styled(Button)({
    height: '24px',
    width: '24px',
    minWidth: '24px',
    ':hover': {
        '&&': {
            color: FlossPalette.WHITE,
        },
    },
});

const CloseIconStyled = styled(CloseIcon)({
    color: FlossPalette.WHITE,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    isolation: 'isolate',
    width: '18px',
    height: '18px',
    flex: 'none',
    flexGrow: 0,
    ':hover': {
        '&&': {
            color: FlossPalette.WHITE,
        },
    },
});

const PracticeOnboarding_Query = graphql(`
    query PracticeOnboarding {
        getPracticeOnboarding {
            portal_onboarding_complete
        }
    }
`);

const PracticeOnboarding_Mutation = graphql(`
    mutation SkipPracticeOnboarding {
        skipOnboarding {
            id
        }
    }
`);

export const GettingStartedRoot: React.FC<{ isByod: boolean }> = ({ isByod }) => {
    const session = useSession();
    const practiceId = session?.organization_id ?? '';
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const history = useHistory();

    const { value: isEquipmentTrackerEnabled = false } = useFeatureFlag('enableEquipmentTracker');
    // If the forceOnboarding flag is on, we display the equipment
    // tracker with fake tracking numbers for testing purposes
    const { value: forceOnboarding } = useFeatureFlag('forceOnboarding');
    const { value: enableGuidedOnboarding } = useFeatureFlag('enableGuidedOnboarding');

    const { data: practiceInfo } = useGetPracticeTrainingDateQuery({
        variables: {
            practiceId,
        },
        skip: !practiceId,
    });

    const [showOnboarding, setShowOnboarding] = React.useState(false);
    const { data: onboardingData, loading: onboardingLoading } = useQuery(PracticeOnboarding_Query);
    const onboardingBannerStatus = onboardingData?.getPracticeOnboarding
        ? onboardingData?.getPracticeOnboarding?.portal_onboarding_complete
        : true;

    const skipOnboarding = useMutation(PracticeOnboarding_Mutation);
    const { submit } = useRootActionCommand(skipOnboarding, {
        onSuccess: () => setShowOnboarding(false),
    });

    const trainingUpcoming =
        !practiceInfo?.getPracticeTrainingDate || dayjs() <= dayjs(practiceInfo.getPracticeTrainingDate);

    React.useEffect(() => {
        AnalyticsClient.track('Practice - Onboarding - Onboarding Home Loaded', { screen: 'OnboardingInbox' });
    }, []);

    React.useEffect(() => {
        setShowOnboarding(!onboardingBannerStatus);
    }, [onboardingBannerStatus]);

    const showEquipmentTracker = !isByod && trainingUpcoming && isEquipmentTrackerEnabled;

    return (
        <div className={classes.root} data-test={'getting-started-root'}>
            {enableGuidedOnboarding && !onboardingLoading && showOnboarding && (
                <BannerContainer onClick={() => history.push('/onboarding')}>
                    <ProgressBarContainer>
                        <InnerProgressBar />
                    </ProgressBarContainer>
                    <FinishSettingUpText variant={isMobile ? 'body2' : 'body1'} color={'WHITE'}>
                        Finish Setting Up Your Account
                    </FinishSettingUpText>
                    <ContinueText color={'WHITE'}>Continue</ContinueText>
                    <CloseButton
                        onClick={event => {
                            event.stopPropagation();
                            void submit({});
                        }}
                        variant={'ghost'}
                    >
                        <CloseIconStyled />
                    </CloseButton>
                </BannerContainer>
            )}
            <div className={classes.outerWrapper}>
                <Text variant={isMobile ? 'h4' : 'h3'} medium>
                    Getting started with Dandy
                </Text>
                <Text variant={isMobile ? 'body2' : 'body1'} color={'DARK_GRAY'} style={{ marginTop: 8 }}>
                    While you’re waiting for your training session, you can familiarize yourself with Dandy so you know
                    everything you need to get started.
                </Text>
                <hr className={classes.divider} />
                <div className={classes.columns}>
                    <div>
                        <Text className={classes.buttonsLabel} variant={'h6'} style={{ color: FlossPalette.GRAY }}>
                            Explore our onboarding resources
                        </Text>
                        <div className={classes.buttons}>
                            <GettingStartedLink
                                icon={logo}
                                iconAlt={'Dandy logo'}
                                title={'The Dandy Ecosystem'}
                                body={
                                    'Learn about the parts of the Dandy Ecosystem and how they  work together to drive better outcomes for your practice.'
                                }
                                externalLink={'22479514298765-The-Dandy-Ecosystem'}
                            />
                            <GettingStartedLink
                                icon={scanner}
                                iconAlt={'Intraoral scanner'}
                                title={'Equipment and Software Setup'}
                                body={"If you've received all your equipment, start here to complete your setup!"}
                                externalLink={'19256250046349-Equipment-and-Software-Setup'}
                            />
                            <GettingStartedLink
                                icon={person}
                                iconAlt={'Person'}
                                title={'Profile Creation and Preferences'}
                                body={
                                    'Ensure your practice gets the most value by customizing your preferences, managing staff profiles, and more.'
                                }
                                externalLink={'12317847927437-Profile-Creation-and-Preferences'}
                            />
                        </div>
                    </div>

                    {(showOnboarding || showEquipmentTracker || forceOnboarding) && (
                        <div className={classes.modulesContainer}>
                            {enableGuidedOnboarding && showOnboarding && <TrainingDataIntakeCompletedCards />}
                            {(showEquipmentTracker || forceOnboarding) && <EquipmentTracker />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
