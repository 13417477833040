import { usePartnerUiSelector } from '../../redux/ui';
import { useShowMobileLayout } from '../../utils/LayoutUtils';
import { InvoicesRoot } from '../invoices/InvoicesRoot.graphql';
import { PracticeSettingsRoot } from './components/PracticeSettingsRoot';
import type { SettingsContentSwitchComponent } from './components/SettingsContentSectionRouter';
import { PracticeSettingsSubNav } from './practice-settings/PracticeSettingsSubNav';
import { PricingGuide } from './practice-settings/pricing-guide/PricingGuide';
import { PracticeSecuritySettings } from './practice-settings/settings/PracticeSecuritySettings.graphql';
import { PracticeEmployees } from './practice-settings/staff/PracticeEmployees';
import { AddStaffWrapper } from './practice-settings/staff/add-edit-staff';
import { StaffRoutes } from './practice-settings/staff/staff-paths';
import { PracticeRouteWithMetadata } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import { SessionGuard, SessionGuardUtils } from '@orthly/session-client';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

const PracticeSettingsRouter: SettingsContentSwitchComponent = ({ path, location }) => {
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);

    return (
        <Switch location={location}>
            <PracticeRouteWithMetadata
                path={`${path}/addresses`}
                title={isNativeApp ? 'Mobile Practice Delivery Addresses' : 'Practice Delivery Addresses'}
            >
                <PracticeSecuritySettings />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={`${path}/payment`}
                title={isNativeApp ? 'Mobile Practice Payment Methods' : 'Practice Payment Methods'}
            >
                <PracticeSecuritySettings />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={`${path}/settings`}
                title={isNativeApp ? 'Mobile Practice Settings' : 'Practice Settings'}
            >
                <PracticeSecuritySettings />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={`${path}/pricing`}
                title={isNativeApp ? 'Mobile Pricing Guide' : 'Pricing Guide'}
            >
                <PricingGuide />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={StaffRoutes.add_staff}
                title={isNativeApp ? 'Mobile Practice Staff' : 'Practice Staff'}
            >
                <SessionGuard
                    fallback={<Redirect to={StaffRoutes.all_staff} />}
                    test={SessionGuardUtils.hasRole(['admin', 'practice__doctor'])}
                >
                    <AddStaffWrapper />
                </SessionGuard>
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={`${path}/staff`}
                title={isNativeApp ? 'Mobile Practice Staff' : 'Practice Staff'}
            >
                <PracticeEmployees />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata path={`${path}/billing`} title={isNativeApp ? 'Mobile Billing' : 'Billing'}>
                <InvoicesRoot />
            </PracticeRouteWithMetadata>
            <Redirect to={`${path}/settings`} />
        </Switch>
    );
};

export const PracticeSettings: React.FC = () => {
    const isMobileLayout = useShowMobileLayout();

    if (isMobileLayout) {
        return <PracticeSettingsRouter path={`/${PracticeScreen.my_practice}`} />;
    }

    return <PracticeSettingsRoot subNavComponent={<PracticeSettingsSubNav />} Router={PracticeSettingsRouter} />;
};
