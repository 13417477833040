import { useIsChairside, usePortalToChairsideBridgeCommand } from '../../utils/chairside.hooks';
import { useCreateTrainingIntakeSurveyResponse } from '../getting_started/components/TrainingDataIntake/hooks/useCreateTrainingIntakeSurveyResponse.graphql';
import { BillingAndDeliveryStep } from './BillingAndDeliveryStep';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingNavigationButtons } from './OnboardingNavigationButtons';
import type { OnboardingStep } from './OnboardingTypes';
import { OnboardingPageIndex } from './OnboardingTypes';
import { PersonalizeTrainingStep } from './PersonalizeTrainingStep';
import { useMutation, useQuery } from '@apollo/client';
import { PracticeScreen } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlPracticeOnboardingStep } from '@orthly/graphql-schema';
import { DEFAULT_TOAST_TIMEOUT, SidebarContainer, Toast } from '@orthly/ui';
import {
    CheckCircleOutlineIcon,
    CheckIcon,
    ClockAlertIcon,
    CreditCardIcon,
    FlossPalette,
    LockIcon,
    PracticeIcon,
    styled,
    Text,
    useScreenIsMobile,
} from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
});
const Body = styled('div')({
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    height: '100%',
    width: '100%',
    display: 'flex',
    overflowY: 'auto',
    gap: '16px',
});
const StyledSidebarContainer = styled(SidebarContainer)({
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: '380px',
});
const Title = styled(Text)({
    padding: '32px 0px',
});
const Step = styled('div')({
    display: 'flex',
    gap: '16px',
    padding: '16px 0px',
});
const ComponentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const GetPracticeOnboarding_Query = graphql(`
    query GetPracticeOnboarding {
        getPracticeOnboarding {
            completed_steps
        }
    }
`);

const TransitionOnboardingStep_Mutation = graphql(`
    mutation TransitionOnboardingStep_Mutation($args: PracticeOnboardingTransitionStepInput!) {
        transitionStep(args: $args) {
            id
            completed_steps
        }
    }
`);

const getPersonalizedTrainingIcon = ({
    personalizedTrainingComplete,
    activeStepIndex,
}: {
    personalizedTrainingComplete: boolean;
    activeStepIndex: OnboardingPageIndex;
}): React.ReactNode => {
    if (personalizedTrainingComplete) {
        return <CheckCircleOutlineIcon color={'primary'} />;
    }
    if (activeStepIndex === OnboardingPageIndex.PersonalizedTraining) {
        return <PracticeIcon color={'primary'} />;
    }

    return <LockIcon color={'disabled'} />;
};

const useProgressPercentage = ({ stepIndex, stepsLength }: { stepIndex: number; stepsLength: number }) => {
    const isChairside = useIsChairside();
    // Chairside include a step for setting up scanner
    const totalSteps = isChairside ? stepsLength + 1 : stepsLength;
    // Progress bar will show half way through current step to indicated the step is in progress
    const halfStepPercent = 1 / totalSteps / 2;
    return (stepIndex / totalSteps + halfStepPercent) * 100;
};

export const OnboardingNavigationLayout: React.VFC = () => {
    const [activeStepIndex, setActiveStepIndex] = React.useState<OnboardingPageIndex>(
        OnboardingPageIndex.BillingAndDelivery,
    );
    const [billingInfoComplete, setBillingInfoComplete] = React.useState(false);
    const [deliveryInfoComplete, setDeliveryInfoComplete] = React.useState(false);
    const [personalizedTrainingComplete, setPersonalizedTrainingComplete] = React.useState(false);

    const { successToastOpen, errorToastOpen, setSuccessToastOpen, setErrorToastOpen } =
        useCreateTrainingIntakeSurveyResponse();
    const history = useHistory();
    const isChairside = useIsChairside();
    const isMobile = useScreenIsMobile();
    const startScannerSetupOnboarding = usePortalToChairsideBridgeCommand('startScannerSetupOnboarding');

    const { data: onboardingData, loading: loadingOnboardingData } = useQuery(GetPracticeOnboarding_Query);
    const [transitionOnboardingStep] = useMutation(TransitionOnboardingStep_Mutation);
    const onStepSuccess = (completedSteps: LabsGqlPracticeOnboardingStep[]) => {
        transitionOnboardingStep({
            variables: {
                args: { completedSteps: completedSteps },
            },
        })
            .then(() => {
                if (completedSteps.includes(LabsGqlPracticeOnboardingStep.BillingInfo)) {
                    setBillingInfoComplete(true);
                }
                if (completedSteps.includes(LabsGqlPracticeOnboardingStep.DeliveryInfo)) {
                    setDeliveryInfoComplete(true);
                }
                if (completedSteps.includes(LabsGqlPracticeOnboardingStep.PersonalizeTraining)) {
                    setPersonalizedTrainingComplete(true);
                }
            })
            .catch(setErrorToastOpen);
    };

    React.useEffect(() => {
        if (!loadingOnboardingData) {
            const completed_steps = onboardingData?.getPracticeOnboarding?.completed_steps;
            const includesBillingInfo = !!completed_steps?.includes(LabsGqlPracticeOnboardingStep.BillingInfo);
            const includesDeliveryInfo = !!completed_steps?.includes(LabsGqlPracticeOnboardingStep.DeliveryInfo);
            const includesPersonalizedTraining = !!completed_steps?.includes(
                LabsGqlPracticeOnboardingStep.PersonalizeTraining,
            );
            setBillingInfoComplete(includesBillingInfo);
            setDeliveryInfoComplete(includesDeliveryInfo);
            setPersonalizedTrainingComplete(includesPersonalizedTraining);

            if (includesBillingInfo && includesDeliveryInfo && includesPersonalizedTraining) {
                isChairside ? startScannerSetupOnboarding?.() : history.push(PracticeScreen.inbox);
            }

            if (includesBillingInfo && includesDeliveryInfo) {
                setActiveStepIndex(OnboardingPageIndex.PersonalizedTraining);
            }
        }
    }, [onboardingData, loadingOnboardingData, history, isChairside, startScannerSetupOnboarding]);

    const steps: OnboardingStep[] = [
        {
            title: 'Update billing & delivery info',
            subTitle: 'Set up your billing to process orders.',
            icon:
                billingInfoComplete && deliveryInfoComplete ? (
                    <CheckCircleOutlineIcon color={'primary'} />
                ) : (
                    <CreditCardIcon
                        color={activeStepIndex === OnboardingPageIndex.BillingAndDelivery ? 'primary' : 'disabled'}
                    />
                ),
            component: (
                <BillingAndDeliveryStep
                    onPaymentMethodSuccess={() => onStepSuccess([LabsGqlPracticeOnboardingStep.BillingInfo])}
                    onDeliveryAddressSuccess={() => onStepSuccess([LabsGqlPracticeOnboardingStep.DeliveryInfo])}
                />
            ),
            saveAndContinueActionDisabled: false,
            saveAndContinueAction: async () => {
                await onStepSuccess([
                    LabsGqlPracticeOnboardingStep.BillingInfo,
                    LabsGqlPracticeOnboardingStep.DeliveryInfo,
                ]);
                setActiveStepIndex(OnboardingPageIndex.PersonalizedTraining);
            },
        },
        {
            title: 'Personalize your training',
            subTitle: 'Get Dandy working for your practice.',
            icon: getPersonalizedTrainingIcon({ personalizedTrainingComplete, activeStepIndex }),
            component: (
                <PersonalizeTrainingStep
                    onPersonalizeTrainingSuccess={() =>
                        onStepSuccess([LabsGqlPracticeOnboardingStep.PersonalizeTraining])
                    }
                    personalizedTrainingComplete={personalizedTrainingComplete}
                />
            ),
            previousAction: () => {
                setActiveStepIndex(OnboardingPageIndex.BillingAndDelivery);
            },
            saveAndContinueActionDisabled: !personalizedTrainingComplete,
            saveAndContinueAction: () => {
                isChairside ? startScannerSetupOnboarding?.() : history.push(PracticeScreen.inbox);
            },
        },
    ];
    const percentComplete = useProgressPercentage({ stepIndex: activeStepIndex, stepsLength: steps.length });

    return (
        <Container>
            <OnboardingHeader showProgressBar percentComplete={percentComplete} />

            <Body>
                {!isMobile && (
                    <StyledSidebarContainer position={'left'}>
                        <div>
                            <Title variant={'h6'}>Let’s get your profile set up</Title>
                            {steps.map((step, index) => {
                                const isActiveStep = index === activeStepIndex;
                                return (
                                    <Step key={step.title}>
                                        {step.icon}
                                        <div>
                                            <Text
                                                variant={'body2'}
                                                medium
                                                color={isActiveStep ? 'PRIMARY_FOREGROUND' : 'GRAY'}
                                            >
                                                {index + 1}. {step.title}
                                            </Text>
                                            <Text variant={'body2'} color={'GRAY'}>
                                                {step.subTitle}
                                            </Text>
                                        </div>
                                    </Step>
                                );
                            })}
                        </div>

                        <OnboardingNavigationButtons steps={steps} activeStepIndex={activeStepIndex} />
                    </StyledSidebarContainer>
                )}

                <ComponentContainer>
                    {!loadingOnboardingData && steps[activeStepIndex]?.component}
                    {isMobile && <OnboardingNavigationButtons steps={steps} activeStepIndex={activeStepIndex} />}
                </ComponentContainer>
            </Body>

            <Toast
                headline={'Training form complete'}
                description={'We got your form submission. Thanks!'}
                open={successToastOpen}
                onDismiss={() => setSuccessToastOpen(false)}
                variant={'success'}
                icon={CheckIcon}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
            <Toast
                headline={'Something went wrong'}
                description={`We can't submit this right now. Please try again later.`}
                open={errorToastOpen}
                onDismiss={() => setErrorToastOpen(false)}
                variant={'warning'}
                icon={ClockAlertIcon}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </Container>
    );
};
