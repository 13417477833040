import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { LogoutButton } from '../../../../components/LogoutButton';
import { usePracticeLogout } from '../../../../redux/auth';
import { useIsChairside, usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import { PracticeUtils } from '../../../../utils/utils';
import { isPlaceholderEmail } from '../../../account/profile-settings/account/utils';
import { LabeledTextField } from '../../components/labeled-field/LabeledFields';
import { useRevealPassword } from '../../components/labeled-field/useRevealPassword';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { CenteredFooterText, FooterLink } from '../../components/page-layout/components/PanelFooterLink';
import { useCreateLoginCredentialsValidation } from './useCreateLoginCredentialsValidation';
import { useCreatePassword } from './useCreatePassword.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-schema';
import { Button } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const CreateLoginCredentials: React.FC<{ user: LabsGqlUserDtoFragment | undefined; preferencesId: string }> = ({
    user,
    preferencesId,
}) => {
    const { value: enableForceUserCredentials = false, loading } = useFeatureFlag('enableForceUserCredentials');
    const history = useHistory();
    const logout = usePracticeLogout();
    const userEmail = user?.email;
    const { action, type } = useRevealPassword();
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const placeholderEmail = userEmail && isPlaceholderEmail(userEmail);
    const isMigration = PracticeUtils.getParsedQueryProperty(`migrate`, location.search) ?? '';
    const isChairside = useIsChairside();
    const doctorCredentialSetupCompletedAction = usePortalToChairsideBridgeCommand('doctorCredentialSetupCompleted');
    const GetPracticeOnboarding_Query = graphql(`
        query GetPracticeOnboardingPortalComplete {
            getPracticeOnboarding {
                meta {
                    completed_at
                }
            }
        }
    `);
    const { data: practiceOnboardingData } = useQuery(GetPracticeOnboarding_Query);

    const {
        isFormValid,
        passwordError,
        confirmedPasswordError,
        emailError,
        setShowPasswordError,
        setShowConfirmedPasswordError,
        setShowEmailError,
    } = useCreateLoginCredentialsValidation({
        password,
        confirmedPassword,
        email,
    });

    const { submit: submitCreatePassword, submitting: submittingCreatePassword } = useCreatePassword();
    const shouldContinue = isFormValid || submittingCreatePassword;
    const hasPlaceholderEmail = Boolean(placeholderEmail && enableForceUserCredentials && !!userEmail);

    React.useEffect(() => {
        if (!placeholderEmail && userEmail) {
            setEmail(userEmail);
        }
        AnalyticsClient.track('Practice - Guided Onboarding - Create Profile', {
            preferencesId,
            email: userEmail,
        });
    }, [placeholderEmail, userEmail, preferencesId]);

    if (loading) {
        return null;
    }

    return (
        <PageLayout
            pageMessage={'Create profile'}
            panelTitle={'Create your login credentials'}
            panelDescription={
                'You will use these credentials to login to your Dandy portal. You can also use this account across multiple locations or practices.'
            }
            onFormSubmit={async e => {
                e.preventDefault();
                await submitCreatePassword({
                    args: {
                        password: password,
                        preferences_id: preferencesId,
                        email,
                    },
                });
                if (isChairside) {
                    doctorCredentialSetupCompletedAction?.({
                        practice_needs_onboarding: !practiceOnboardingData?.getPracticeOnboarding?.meta?.completed_at,
                    });
                } else {
                    history.push('/onboarding');
                }
            }}
        >
            <LabeledTextField
                isRequired
                label={'Email'}
                placeholder={'Enter your email address'}
                disabled={!hasPlaceholderEmail}
                value={hasPlaceholderEmail ? email : userEmail}
                onChange={(value: string | undefined) => {
                    if (hasPlaceholderEmail && value) {
                        setEmail(value);
                    }
                }}
                TextFieldProps={{
                    type: 'email',
                    onBlur: () => {
                        setShowEmailError(email !== '');
                    },
                    error: !!emailError,
                    helperText: emailError,
                }}
            />
            <LabeledTextField
                isRequired
                label={'Password'}
                placeholder={'Enter your password'}
                value={password}
                onChange={(next = '') => {
                    setPassword(next);
                }}
                TextFieldProps={{
                    type,
                    onBlur: () => {
                        setShowPasswordError(password !== '');
                    },
                    error: !!passwordError,
                    helperText: passwordError,
                }}
                action={action}
            />
            <LabeledTextField
                isRequired
                label={'Repeat Password'}
                placeholder={'Re-enter your password'}
                value={confirmedPassword}
                onChange={(next = '') => {
                    setConfirmedPassword(next);
                }}
                TextFieldProps={{
                    type,
                    onBlur: () => {
                        setShowConfirmedPasswordError(confirmedPassword !== '');
                    },
                    error: !!confirmedPasswordError,
                    helperText: confirmedPasswordError,
                }}
                action={action}
            />
            <Button variant={'primary'} fullWidth type={'submit'} disabled={!shouldContinue}>
                Continue
            </Button>
            {!isChairside && (
                <CenteredFooterText>
                    {isMigration ? (
                        <LogoutButton variant={'ghost'} />
                    ) : (
                        <FooterLink onClick={logout}>Already an existing user? Login instead</FooterLink>
                    )}
                </CenteredFooterText>
            )}
        </PageLayout>
    );
};
