import { OrderDetailSection } from './OrderDetailSection';
import type { OrderDetailSharedProps } from './OrderDetailSection.types';
import { ScanExportModelViewerBlock } from '@orthly/veneer';
import React from 'react';

export const OrderDetailScansSection: React.FC<OrderDetailSharedProps> = ({ order }) => {
    return (
        <OrderDetailSection>
            <ScanExportModelViewerBlock order={order} />
        </OrderDetailSection>
    );
};
