import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const OrderDetailSection = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
    ({ children }, ref) => {
        return (
            <Grid container ref={ref}>
                <Grid container style={{ padding: '0 12px' }}>
                    {children}
                </Grid>
            </Grid>
        );
    },
);
