import { PracticeSettingsBaseContent } from '../account/components/PracticeSettings/PracticeSettingsBaseContent';
import { PracticeSettingsTitle } from '../account/components/PracticeSettings/PracticeSettingsBaseTitle';
import { TrainingDialogStage } from '../getting_started/components/TrainingDataIntake/TrainingDataIntake.types';
import { TrainingDataIntakeForm } from '../getting_started/components/TrainingDataIntake/TrainingDataIntakeForm';
import { useCreateTrainingIntakeSurveyResponse } from '../getting_started/components/TrainingDataIntake/hooks/useCreateTrainingIntakeSurveyResponse.graphql';
import {
    useCanSubmitTrainingData,
    useOnSubmitTrainingDataAction,
} from '../getting_started/components/TrainingDataIntake/hooks/useSubmitTrainingData';
import { useTrainees } from '../getting_started/components/TrainingDataIntake/hooks/useTrainees';
import { Button, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    background: FlossPalette.WHITE,
    overflow: 'auto',
    minHeight: 0,
    width: '100%',
});
const Content = styled('div')({
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});
const FormWrapper = styled('div')({
    maxWidth: '522px',
});
const Footer = styled('div')<{ hasTopBorder: boolean }>(({ theme, hasTopBorder }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    borderTop: hasTopBorder ? `1px solid ${FlossPalette.STROKE_LIGHT}` : 'none',
}));
const ButtonsLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1),
    maxWidth: '522px',
    marginBottom: 4,
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
}));
const StyledButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        height: '32px',
    },
}));

const useTitles = (stage: TrainingDialogStage) => {
    const { trainees, dentists } = useTrainees();

    if (stage === TrainingDialogStage.FIRST) {
        return {
            title: 'Your practice’s training session',
            description: `Please fill out this form for each person who plans on attending Launch Training. This will help us tailor the training to your staff's current scanning experience.`,
            nextButtonText: dentists.length > 0 ? 'Continue' : 'Submit',
            hasTopBorder: trainees.length > 1,
        };
    }

    return {
        title: 'Retraction methods, prep styles, & more',
        description: 'Help us make your training session as effective as possible.',
        nextButtonText: 'Submit',
        hasTopBorder: dentists.length > 1,
    };
};

interface PersonalizeTrainingStepProps {
    onPersonalizeTrainingSuccess(): void;
    personalizedTrainingComplete: boolean;
}

export const PersonalizeTrainingStep: React.VFC<PersonalizeTrainingStepProps> = ({
    onPersonalizeTrainingSuccess,
    personalizedTrainingComplete,
}) => {
    const [stage, setStage] = React.useState<TrainingDialogStage>(TrainingDialogStage.FIRST);
    const { title, description, nextButtonText, hasTopBorder } = useTitles(stage);
    const { submitting } = useCreateTrainingIntakeSurveyResponse();

    const canSubmit = useCanSubmitTrainingData(stage);
    const onSubmit = useOnSubmitTrainingDataAction({
        stage,
        setStage,
        onSuccess: () => onPersonalizeTrainingSuccess(),
    });

    return (
        <Container>
            <PracticeSettingsTitle title={'Personalize your training'} style={{ marginTop: 0 }} />
            <PracticeSettingsBaseContent
                Content={
                    <Content>
                        <Text variant={'body1'} medium>
                            {title}
                        </Text>

                        <Text variant={'body2'} color={'GRAY'}>
                            {description}
                        </Text>
                        <FormWrapper>
                            <TrainingDataIntakeForm stage={stage} fullheight />
                        </FormWrapper>
                    </Content>
                }
            />
            <Footer hasTopBorder={hasTopBorder}>
                <ButtonsLayout>
                    {stage === TrainingDialogStage.SECOND && (
                        <StyledButton
                            variant={'secondary-gray'}
                            onClick={() => setStage(TrainingDialogStage.FIRST)}
                            disabled={submitting || personalizedTrainingComplete}
                        >
                            Back
                        </StyledButton>
                    )}
                    <StyledButton
                        variant={'primary'}
                        onClick={onSubmit}
                        disabled={!canSubmit || submitting || personalizedTrainingComplete}
                    >
                        {nextButtonText}
                    </StyledButton>
                </ButtonsLayout>
                <Text variant={'caption'} color={'GRAY'}>
                    Fields marked with * are required to proceed.
                </Text>
            </Footer>
        </Container>
    );
};
