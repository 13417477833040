import { ScanFlaggedWarning } from '../../../../../components/WarningActionCards/ScanFlaggedWarning.graphql';
import { InfoBlock } from '../../OrderDetailBodyHelperComponents';
import { OrderDetailAlignersAlert } from '../OrderDetailAlignersAlert';
import { OrderDetailsCbctScanManagement } from '../OrderDetailCbctScanManagement.graphql';
import { OrderDetailDenturesWaxRimDeliveredAlert } from '../OrderDetailDenturesWaxRimDeliveredAlert';
import { OrderDetailTracking } from '../OrderDetailTracking';
import { OrderDetailTryInFeedbackAlert } from '../OrderDetailTryInFeedbackAlert';
import { OrderDetailTryInFeedbackNeedsScanAlert } from '../OrderDetailTryInFeedbackNeedsScanAlert';
import { OrderDetailsRefabs } from '../OrderDetailsRefabs';
import { OrderDetailSection } from './OrderDetailSection';
import type { OrderDetailSharedProps } from './OrderDetailSection.types';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import { OrderDetailDentures, OrderDetailPartials, OrderDetailTryInFeedback } from '@orthly/veneer';
import React from 'react';

const OrderDetailBodyTopSection: React.FC<OrderDetailSharedProps> = ({ order, refetch, refetchMessages }) => {
    return (
        <Grid container>
            <ScanFlaggedWarning orderDetail order={order} refetch={refetch} />

            <OrderDetailTryInFeedbackAlert order={order} />

            {order.previous_fulfillment_order_id && (
                <OrderDetailTryInFeedbackNeedsScanAlert
                    order={{ ...order, previous_fulfillment_order_id: order.previous_fulfillment_order_id }}
                />
            )}

            <OrderDetailDenturesWaxRimDeliveredAlert order={order} />

            {order.aligner_case && (
                <OrderDetailAlignersAlert
                    patient_first_name={order.patient.first_name}
                    order={order}
                    status={order.status}
                />
            )}
            <InfoBlock order={order} />
            <OrderDetailTracking order={order} refetch={refetch} />
            {OrderItemV2Utils.parseItems(order.items_v2).some(
                OrderItemV2Utils.itemIsType(LabOrderItemSKUType.SurgicalGuide),
            ) && <OrderDetailsCbctScanManagement order={order} refetch={refetch} refetchMessages={refetchMessages} />}
        </Grid>
    );
};

export const OrderDetailOverviewSection: React.VFC<OrderDetailSharedProps> = ({ order, refetch, refetchMessages }) => {
    return (
        <OrderDetailSection>
            <OrderDetailBodyTopSection refetch={refetch} refetchMessages={refetchMessages} order={order} />
            <OrderDetailsRefabs order={order} />
            {order.previous_fulfillment_order_id && (
                <OrderDetailTryInFeedback orderId={order.previous_fulfillment_order_id} isPreviousOrder={true} />
            )}
            {order.dentures_fulfillment && <OrderDetailDentures order={order} />}
            {order.partials_fulfillment && <OrderDetailPartials order={order} />}
        </OrderDetailSection>
    );
};
