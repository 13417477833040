import { usePartnerUiSelector } from '../../../../../redux/ui';
import { OrderDetailSection } from './OrderDetailSection';
import type { OrderDetailSharedProps } from './OrderDetailSection.types';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import {
    OrderDetailDoctorPhotosBlock,
    OrderDetailOriginalPhotosBlock,
    OrderDetailShadeMatchBlock,
    useOrderDetailImages,
} from '@orthly/veneer';
import React from 'react';

export const OrderDetailPhotosSection: React.FC<OrderDetailSharedProps> = ({ order, refetch }) => {
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const isMobile = useScreenIsMobile();

    const imageSources = useOrderDetailImages(order, false);
    const showPhotos = imageSources.length > 0 || (order?.scan_export.image_urls ?? []).length > 0;

    if (!showPhotos) {
        return null;
    }

    return (
        <OrderDetailSection>
            <OrderDetailDoctorPhotosBlock
                order={order}
                refetchOrder={refetch}
                isPSR={false}
                disablePhotoDownload={isNativeApp || isMobile}
            />
            <OrderDetailOriginalPhotosBlock
                order={order}
                refetchOrder={refetch}
                isPSR={false}
                disablePhotoDownload={isNativeApp || isMobile}
            />
            {order.scan_export.image_urls && (
                <OrderDetailShadeMatchBlock orderId={order.id} imageUrls={order?.scan_export.image_urls} />
            )}
        </OrderDetailSection>
    );
};
